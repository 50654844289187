@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    overflow-x: hidden;
}
.btn{
    background: #ff6766;
    box-shadow: #6b6b6b;
}

@media (max-width: 767px) {
  section{
    padding-bottom: 10px;
  }
}

.btn:hover{
    background: #1E1E1E;
    color: #F5F5FA
}

.active {
  background-color: #2ADBCD;
  color: #fff;
  padding: 1rem;
  border-radius: 100%;
}

@layer base {
  .btn {
    @apply rounded-full text-primary font-medium ;
  }

  .btn-sm {
    @apply h-[48px] lg:h-[30px] px-6 text-xs lg:text-base;
  }
  .btn-lg {
    @apply h-[56px] lg:h-[50px] px-10 text-sm lg:text-lg;
  }
  .btn-link {
    @apply text-base;
  }
}


  .footer {
  
    bottom: 0 !important;
    padding-top: 10px !important;
    padding-bottom: 5px !important ;
    background-color: #ff6766 !important;
    
  }
  .footer-copywright {
    text-align: center !important;
  }
  
  .footer-body {
    z-index: 1;
    display: flex;
    text-align: center !important;
    justify-content: center;
    min-height: 5vh;
  }
  
  @media (max-width: 767px) {
    .footer-copywright {
      text-align: center !important;
    }
  
    .footer-body {
      text-align: center !important;
      
    }
  }
  
  .footer h3 {
    font-size: 1em;
    color: #1E1E1E !important;
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
  }
  .footer-icons {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
    padding: 0 !important;
    display: flex;
    flex-wrap: row;
  }


  .wave {
    animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
    animation-duration: 1.1s;
    animation-iteration-count: infinite;
    transform-origin: 70% 70%;
    display: inline-block;
  }
  
  @keyframes wave-animation {
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(14deg);
    } 
    20% {
      transform: rotate(-8deg);
    }
    30% {
      transform: rotate(14deg);
    }
    40% {
      transform: rotate(-4deg);
    }
    50% {
      transform: rotate(10deg);
    }
    60% {
      transform: rotate(0deg);
    } 
    100% {
      transform: rotate(0deg);
    }

  }

  .skills .main-container .container{
    position: relative;
    max-width: 80px;
    width: 100%;
    margin: 0 10px;
    padding: 10px 20px;
    border-radius: 7px;
  }
  
  .title-text {
    text-align: center;
    background-color: #2ADBCD;
    font-weight: 500;
    border-radius: 50px;
    margin-top: 25px;
    margin-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 25px;
  }
  
  .main-container .container .skill-box {
    width: 100%;
    margin: 25px 0;
  }
  
  .skill-box .title{
    display: block;
    font-size: 14px;
    font-weight: 600;
  }
  
  .skill-box .skill-bar{
    height: 8px;
    width: 100%;
    border-radius: 6px;
    margin-top: 6px;
    background: rgba(16, 2, 19, 0.1);
  }
  
  .skill-box .skill-per{
    position: relative;
    display: block;
    height: 100%;
    width: 95%;
    border-radius: 6px;
    animation: progress 0.4s ease-in-out forwards;
    opacity: 0;
  }
  

  .education-box .education-content::before{
    content: '';
    position: absolute;
    top: 0;
    width: 1rem;
    height: 1rem;
    background-color:#2ADBCD;
    border-radius: 50%;
    top: 0;
    left: -0.5rem;

  }

  .swiper-pagination .swiper-pagination-bullet {
    background-color: #2ADBCD;
}